<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Property - {{name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="/business-partners/properties">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

       <v-toolbar color="secondary" flat dense>
        <v-btn
          outlined
          color="toolbarIcon"
          text
          :to="`/bp/properties/create/${id}`"
        >
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="cqg1Headers"
              :items="cqg1"
              :search="search"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      bpProperty: {},
      id: null,
      name: null,
      cqg1: [],
      cqg1Headers: [{ text: "Name", value: "Name" }],
    };
  },
  watch: {
      "$route.params.id": {
          handler: "getProperties",
          immediate: true
      }
  },
  methods: {
    getProperties(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_properties/${val}`)
        .then((res) => {
          if(res.ResultCode == 1200) {
              self.id = res.ResponseData.id;
              self.name = res.ResponseData.GroupName;
              self.bpProperty = res.ResponseData;
              self.cqg1 = res.ResponseData.cqg1;
          }
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style  scoped>
</style>